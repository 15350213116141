<template>
    <v-container class="my-10">
        <div :class="`${id ? '' : 'background-container-create'}`" />
        <div class="d-flex align-self-start mb-4">
            <v-btn
                icon
                color="accent darken-3"
                class="align-self-center background"
                link
                @click="backtoPrevRoute()"
            >
                <v-icon left> fa-solid fa-chevron-left </v-icon>
            </v-btn>
            <h2 class="primary--text main-heading">
                {{ formCreateEditComputed.pageTitle }}
            </h2>
            <v-divider class="mx-4" inset vertical> </v-divider>
            <v-spacer></v-spacer>
        </div>
        <div
          v-if="getServiceByIdStatus_Pending"
        >
         <v-skeleton-loader
            type="card-heading, list-item-three-line, list-item-three-line, image, list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line, actions"
         >
         </v-skeleton-loader>
        </div>
        <div
         v-else-if="getServiceByIdStatus_Error"
         class="d-flex flex-column align-center"
        >
            <h1 class="main-heading error--text">Something goes wrong!</h1>
            <p>Service with ID {{id}} cannot be loaded.</p>
            <v-img
             src="../../assets/noData.svg"
             width="300px"
             height="250px"
             contain
             class="mt-10"
            ></v-img>
        </div>
        <base-card 
         v-else 
         class="container-card container"
        >
            <template #content>
                <v-container>
                    <div class="d-flex align-center">
                        <v-icon left color="primary">
                            {{ formCreateEditComputed.icon }}
                        </v-icon>
                        <h1 class="main-heading form-title">
                            {{ formCreateEditComputed.formTitle }}
                        </h1>
                    </div>
                    <text-input
                        :input-value.sync="formValues.title"
                        :current-value="formValues.title"
                        :in-valid.sync="isNameInvalid"
                        :validation.sync="validationErrors"
                        :label="$t(`Admin.service_box.createToolForm.name`)"
                    >
                    </text-input>
                    <select-input
                        :input-value.sync="formValues.service_groups"
                        :current-value="formValues.service_groups"
                        key-value="name"
                        :multiple="true"
                        :in-valid.sync="isServiceGroupInvalid"
                        :required-input="false"
                        :items="serviceGroupList"
                        icons="fa fa-check"
                        :label="$t(`Admin.service_box.createToolForm.serviceGroup`)"
                    >
                    </select-input>
                    <!-- <text-input
                        :input-value.sync="formValues.content_type"
                        :current-value="formValues.content_type"
                        :in-valid.sync="isMaterialTypeInvalid"
                        :label="$t(`Admin.service_box.createToolForm.materialType`)"
                    >
                    </text-input> -->
                    <select-input
                     :input-value.sync="formValues.content_type"
                     :current-value="formValues.content_type"
                     key-value="name"
                     :multiple="false"
                     :required-input="false"
                     :in-valid.sync="isMaterialTypeInvalid"
                     :items="materialTypeList"
                     icons="fa fa-link"
                     :label="$t(`Admin.service_box.createToolForm.materialType`)"
                    >
                    </select-input>
                    <select-input
                        :input-value.sync="formValues.languages"
                        :current-value="formValues.languages"
                        key-value="name"
                        :multiple="true"
                        :required-input="false"
                        :in-valid.sync="isLanguageInvalid"
                        :items="serviceLanguageList"
                        icons="fa fa-language"
                        :label="$t(`Admin.service_box.createToolForm.languages`)"
                    >
                    </select-input>
                    <select-input
                        :input-value.sync="formValues.proficiency_level"
                        :current-value="formValues.proficiency_level"
                        key-value="name"
                        :multiple="false"
                        :required-input="false"
                        :in-valid.sync="isProficiencyInvalid"
                        :items="proficiency"
                        icons="fa fa-chart-pie"
                        :label="$t(`Admin.service_box.createToolForm.proficiency`)"
                    >
                    </select-input>
                    <!-- <text-area
                        :input-value.sync="formValues.description"
                        :current-value="formValues.description"
                        :in-valid.sync="isDescriptionInvalid"
                        :validation.sync="validationErrors"
                        :required-input="false"
                        :label="$t(`Admin.service_box.createToolForm.description`)"
                    >
                    </text-area> -->
                    <text-editor
                        v-model="formValues.description"
                        :current-value="formValues.description"
                        :validation.sync="validationErrors"
                        :in-valid.sync="isDescriptionInvalid"
                        :label="$t(`Admin.service_box.createToolForm.description`)"
                        :placeholder="false"
                    >
                    </text-editor>
                    <p 
                     class="mb-7 mt-1 text-end text-caption"
                    >
                     <span :class="`${descriptionLength > 30000 ? 'error--text' : ''}`">
                       {{ descriptionLength }}
                     </span>/
                     <span>30000</span>
                    </p>
                    <youtube-link-input
                        :input-value.sync="formValues.youtube_url"
                        :current-value="formValues.youtube_url"
                        :in-valid.sync="isYoutubeURLInvalid"
                        icons="fa fa-video"
                        :label="$t(`Admin.service_box.createToolForm.video_link`)"
                        @delete-video="deleteVideoUrl()"
                    >
                    </youtube-link-input>
                    <social-media-input
                      :input-value.sync="formValues.additional_url"
                      :current-value="formValues.additional_url"
                      :in-valid.sync="isAdditionalUrlInvalid"
                      :required-input="false"
                      :valid-url="false"
                      icons="fa fa-globe-europe"
                      :label="$t(`Admin.service_box.createToolForm.additional_link`)"
                    >
                    </social-media-input>
                    <document-input
                      :file-url.sync="formValues.file"
                      :current-file="formValues.file"
                      :required-input="false"
                      :validation.sync="validationErrors"
                      :in-valid.sync="isDocumentURLInvalid"
                      @delete-file="deleteFile()"
                    >
                    </document-input>
                    <div class="d-flex align-center">
                        <v-icon left color="primary">fa fa-info</v-icon>
                        <h1 class="main-heading">Is service available ?</h1>
                    </div>
                    <select-input
                     :input-value.sync="formValues.availability"
                     :current-value="formValues.availability"
                     key-value="name"
                     :multiple="false"
                     :in-valid.sync="isAvalabilityInvalid"
                     :items="availability"
                     :required-input="false"
                     :icons="`${formValues.availability == 'Available' ? 'fa fa-eye' : 'fa fa-eye-slash'}`"
                     style="max-width: 250px;"
                     label="Service Status"
                    >
                    </select-input>
                    <v-divider class="my-5"></v-divider>
                    <v-row class="align-center mr-4">
                        <v-col
                         cols="12"
                         md="6"
                         sm="12"
                         class="d-flex justify-center justify-md-start mt-5"
                        >
                         <ul v-if="validationErrors.length != 0">
                            <div class="d-flex">
                                <v-icon small left color="warning">
                                    fa fa-warning
                                </v-icon>
                                <h3 class="main-heading">
                                    Form failed to Submit!
                                </h3>
                            </div>
                            <li
                             v-for="(item, index) in validationErrors"
                             :key="index"
                             style="list-style: none;"
                             class="error--text text-sm-center"
                            >
                             {{ item.toString() }}
                            </li>
                         </ul>
                        </v-col>
                        <v-col
                         cols="12"
                         md="6"
                         sm="12"
                         class="d-flex justify-center justify-md-end mt-5">
                            <v-btn
                                depressed
                                color="primary"
                                :loading="createServiceStatus_Pending || editServiceStatus_Pending"
                                :disabled="toggleSubmitButton"
                                @click="selectAction()"
                            >
                                <v-icon left small> fa-solid fa-save </v-icon>
                                {{ formCreateEditComputed.formButton }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </template>
        </base-card>
    </v-container>
</template>

<script>
import { apiStatus } from "@/api/constants/apiStatus";
import { withAsync } from "@/helpers/withAsync";
import { apiStatusComputed } from "@/api/helpers/computedApiStatus";
import { createToolBoxService, updateToolBoxService, getToolBoxService, getToolBoxServiceLanguageList, getToolBoxServiceGroupList } from "@/api/adminApi.js";
import BaseCard from "../../components/base/BaseCard.vue";
import TextInput from "../../components/Inputs/TextInput.vue";
import SelectInput from "../../components/Inputs/SelectInput.vue";
import MailInput from "../../components/Inputs/MailInput.vue";
// import TextArea from "../../components/Inputs/TextArea.vue";
import TextEditor from "../../components/Inputs/TextEditor.vue";
import YoutubeLinkInput from "../../components/Inputs/YoutubeLinkInput.vue";
import DocumentInput from "../../components/Inputs/DocumentInput.vue";
import SocialMediaInput from "../../components/Inputs/SocialMediaInput.vue";

export default {
    name: "CreateEditToolbox",
    props: {
        id: {
            required: false,
            type: [Number, String],
            default: null,
        },
    },
    components: {
        BaseCard,
        TextInput,
        SelectInput,
        MailInput,
        // TextArea,
        TextEditor,
        YoutubeLinkInput,
        DocumentInput,
        SocialMediaInput,
    },
    data() {
        return {
            createServiceStatus: apiStatus.Idle,
            editServiceStatus: apiStatus.Idle,
            getServiceByIdStatus: apiStatus.Idle,
            getServiceGroupListStatus: apiStatus.Idle,
            getServiceLanguageListStatus: apiStatus.Idle,
            serviceGroupList: [],
            materialTypeList: ["Video", "PDF", "Web Link", "Image"],
            serviceLanguageList: [],
            proficiency: ["Advanced", "Intermediate", "Beginner"],
            availability: ["Available", "Not Available"],
            formValues: {
                title: "",
                youtube_url: "",
                content_type: "",
                description: "",
                service_groups: [],
                proficiency_level: [],
                additional_url: "",
                languages: [],
                file: "",
                availability: ""
            },
            descriptionLength: 0,
            isPDFChanged: false,
            isServicesChanged: false,
            isLanguagesChanged: false,
            validationErrors: [],
            isNameInvalid: this.id ? false : true,
            isProficiencyInvalid: this.id ? false : true,
            isMaterialTypeInvalid: this.id ? false : true,
            isServiceGroupInvalid: this.id ? false : true,
            isLanguageInvalid: this.id ? false : true,
            isYoutubeURLInvalid: this.id ? false : true,
            isDocumentURLInvalid: this.id ? false : true,
            isDescriptionInvalid: this.id ? false : true,
            isAvalabilityInvalid: this.id ? false : true,
            isAdditionalUrlInvalid: this.id ? false : true,
        };
    },
    computed: {
        ...apiStatusComputed(["createServiceStatus", "editServiceStatus", "getServiceByIdStatus", "getServiceGroupListStatus", "getServiceLanguageListStatus"]),
        // backtoPrevRoute() {
        //     return { name: "Toolbox" };
        // },
        toggleSubmitButton() {
            if (this.id) {
                for (let key in this.formValues) {
                    if (this.formValues.hasOwnProperty(key)) {
                        return this.isValidated();
                    }
                }
                return true;
            }
            return this.isValidated();
        },
        formCreateEditComputed() {
            return {
                pageTitle: this.id
                    ? this.$t(
                          `Admin.service_box.createToolPage.pageTitleBannerEdit`
                      ) +
                      " - " +
                      `${this.id}`
                    : this.$t(
                          `Admin.service_box.createToolPage.pageTitleBannerCreate`
                      ),
                icon: this.id ? "fa-solid fa-edit" : "fa-solid fa-pen",
                formTitle: this.id
                    ? this.$t(`Admin.service_box.createToolPage.formTitleEdit`)
                    : this.$t(`Admin.service_box.createToolPage.formTitleCreate`),
                formButton: this.id
                    ? this.$t(`Admin.service_box.createToolPage.formSaveButton`)
                    : this.$t(`Admin.service_box.createToolPage.formSubmitButton`),
            };
        },
    },
    methods: {
        deleteFile() {
            this.formValues.file = '';
        },
        deleteVideoUrl() {
            this.formValues.youtube_url = '';
        },
        backtoPrevRoute() {
            this.$router.go(-1);
        },
        async getServiceLanguageList() {
            this.getServiceLanguageListStatus = apiStatus.Pending;

            const { response, error } = await withAsync(getToolBoxServiceLanguageList);

            if (error) {
                this.getServiceLanguageListStatus = apiStatus.Error
                return;
            }

            this.getServiceLanguageListStatus = apiStatus.Success;
            this.serviceLanguageList = response.data;
            this.serviceLanguageList = this.serviceLanguageList.map(lang => {
                return {
                    id: lang.id,
                    name: lang.name.charAt(0).toUpperCase() + lang.name.slice(1)
                }
            })
        },
        async getServiceGroupList() {
            this.getServiceGroupListStatus = apiStatus.Pending;

            const { response, error } = await withAsync(getToolBoxServiceGroupList);

            if (error) {
                this.getToolBoxServiceGroupList = apiStatus.Error
                return;
            }

            this.getServiceGroupListStatus = apiStatus.Success;
            this.serviceGroupList = response.data;
        },
        async createService() {
            this.createServiceStatus = apiStatus.Pending;

            let formatedData = new FormData();

            formatedData.append('title', this.formValues.title);
            if (this.formValues.description != '') {
                formatedData.append('description', this.formValues.description);
            }
            if (this.formValues.content_type != '') {
                formatedData.append('content_type', this.formValues.content_type);
            }
            if(this.formValues.availability != '') {
                formatedData.append('availability', this.formValues.availability);
            }
            if(this.formValues.proficiency_level != '') {
                formatedData.append('proficiency_level', this.formValues.proficiency_level);
            }
            if(this.formValues.service_groups != '') {
                formatedData.append('service_group', this.formValues.service_groups);
            }
            if(this.formValues.youtube_url != '') {
                formatedData.append('youtube_url', this.formValues.youtube_url);
            }
            if(this.formValues.languages != '') {
                formatedData.append('language', this.formValues.languages);
            }
            if(this.formValues.file != '') {
                formatedData.append('file_1', this.formValues.file);
                formatedData.append('files_number', 1)
            }
            if(this.formValues.additional_url != '') {
                formatedData.append('additional_url', this.formValues.additional_url);
            }
            // formatedData.append('files_number', 1)
            // formatedData.append('description', this.formValues.description);
            // formatedData.append('content_type', this.formValues.content_type);
            // formatedData.append('availability', this.formValues.availability);
            // formatedData.append('proficiency_level', this.formValues.proficiency_level);
            // formatedData.append('service_group', this.formValues.service_groups);
            // formatedData.append('youtube_url', this.formValues.youtube_url);
            // formatedData.append('language', this.formValues.languages);
            // formatedData.append('file_1', this.formValues.file);

            const { response, error } = await withAsync(createToolBoxService, formatedData);

            if (error) {
                this.createServiceStatus = apiStatus.Error
                this.validationErrors = error.response.data.metadata.response_message;
                return;
            }

            this.createServiceStatus = apiStatus.Success;
            this.$router.push({ name: "Toolbox" });
        },
        async getServiceById() {
            this.getServiceByIdStatus = apiStatus.Pending;

            const { response, error } = await withAsync(getToolBoxService, this.id);

            if (error) {
                this.getServiceByIdStatus = apiStatus.Error
                return;
            }
            
            this.getServiceByIdStatus = apiStatus.Success;
            
            // const {
            //     availability,
            //     content_type,
            //     description,
            //     id,
            //     formatedFile = response.data.file[0].url,
            //     languages,
            //     proficiency_level,
            //     service_groups,
            //     title,
            //     updated_at,
            //     youtube_url
            // } = response.data;
            
            // this.formValues = { availability, content_type, description, id, file: formatedFile, languages, proficiency_level, service_groups, title, updated_at, youtube_url };
            this.formValues = response.data;

            // console.log('fetched form values', this.formValues);
        },
        async editService() {
            this.editServiceStatus = apiStatus.Pending;

            let formatedData = new FormData();

            formatedData.append('title', this.formValues.title);
            if(this.formValues.description != null) {
                formatedData.append('description', this.formValues.description);
            }
            if(this.formValues.content_type != null) {
                formatedData.append('content_type', this.formValues.content_type);
            }
            if(this.formValues.availability != null) {
                formatedData.append('availability', this.formValues.availability);
            }
            if(this.formValues.proficiency_level != null) {
                formatedData.append('proficiency_level', this.formValues.proficiency_level);
            }
            if(this.formValues.youtube_url != null) {
                formatedData.append('youtube_url', this.formValues.youtube_url);
            }
            if(this.formValues.additional_url != null) {
                formatedData.append('additional_url', this.formValues.additional_url);
            }
            // formatedData.append('files_number', 1)
            // formatedData.append('description', this.formValues.description);
            // formatedData.append('content_type', this.formValues.content_type);
            // formatedData.append('availability', this.formValues.availability);
            // formatedData.append('proficiency_level', this.formValues.proficiency_level);
            // formatedData.append('youtube_url', this.formValues.youtube_url);
            
            // console.log('testing pdf', this.isPDFChanged, this.formValues.file);
            if(!this.isPDFChanged) {
                formatedData.append('file_1', this.formValues.file);
                formatedData.append('files_number', 1);
            }

            if(this.isServicesChanged) {
                let formatedGroups = this.formValues.service_groups.map(group => {
                    return group.id;
                })

               let hasObjectGroup = this.formValues.service_groups.some(item => {
                 if(item.id) {
                    return true;
                }
              })
              formatedData.append('service_group', `${ hasObjectGroup ? formatedGroups : this.formValues.service_groups }`);
            }

            if(this.isLanguagesChanged) {
                let formatedLanguage = this.formValues.languages.map(language => {
                   return language.id;
               })

               let hasObjectLang = this.formValues.languages.some(item => {
                 if(item.id) {
                    return true;
                }
               })
               formatedData.append('language', `${ hasObjectLang ? formatedLanguage : this.formValues.languages }`);
            }
            // formatedData.append('service_group', `${ hasObjectGroup ? formatedGroups : this.formValues.service_groups }`);
            // formatedData.append('language', `${ hasObjectLang ? formatedLanguage : this.formValues.languages }`);
            

            let payload = {
                id: this.id,
                data: formatedData
            }

            const { response, error } = await withAsync(updateToolBoxService, payload);

            if (error) {
                this.editServiceStatus = apiStatus.Error;
                this.validationErrors = error.response.data.metadata.response_message;
                return;
            }

            this.editServiceStatus = apiStatus.Success;
            this.$router.push({ name: "Toolbox" });
        },
        selectAction() {
            this.id ? this.editService() : this.createService();
        },
        isValidated() {
            if(this.isNameInvalid) return true;
            // if(this.isProficiencyInvalid) return true;
            // if(this.isMaterialTypeInvalid) return true;
            // if(this.isServiceGroupInvalid) return true;
            // if(this.isLanguageInvalid) return true;
            // if(this.isYoutubeURLInvalid) return true;
            // if(this.isDocumentURLInvalid) return true;
            // if(this.isDescriptionInvalid) return true;
            // if(this.isAvalabilityInvalid) return true;
        },
    },
    watch: {
        "formValues.file": {
            handler(newVal) {
                if(newVal == undefined || newVal.length <= 0) {
                    this.isPDFChanged = !this.isPDFChanged;
                }
                else {
                    this.isPDFChanged = !this.isPDFChanged;
                }
            },
            deep: true,
        },
        "formValues.service_groups": {
            handler(newVal) {
                if(newVal) {
                    this.isServicesChanged = true;
                }
            },
            deep: true,
        },
        "formValues.languages": {
            handler(newVal) {
                if(newVal) {
                    this.isLanguagesChanged = true;
                } 
            },
            deep: true,
        },
        "formValues.description": {
            handler(newVal) {
                if(newVal) {
                    this.descriptionLength = newVal.length;
                }
            },
            deep: true,
        }
    },
    created() {
        this.getServiceGroupList();
        this.getServiceLanguageList();
        if (this.id) {
            this.getServiceById();
        }
    },
};
</script>

<style scoped>
.container {
    background: rgba(255, 255, 255, 0.9);
    z-index: 2;
}
.background-container-create {
    position: relative;
}
.background-container-create::before {
    content: "";
    width: 300px;
    height: 1190px;
    position: absolute;
    z-index: 1;
    top: 65px;
    left: -20px;
    filter: blur(1px);
    border-radius: 10px;
    background-image: linear-gradient(50deg, #eee 0%, #fff 100%);
}

@media screen and (max-width: 580px) {
    .form-title {
        font-size: 1rem !important;
    }
}
</style>
