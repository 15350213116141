<template>
  <div>
    <div
     v-if="fileUrl != '' && fileUrl != undefined "
     class="d-flex justify-center mx-0 mt-2 mb-8 pa-md-4 px-6"
    >
     <iframe
      :src="fileUrl[0].url"
      loading="eager"   
      width="100%"
      height="250px"
     />
    <v-btn
     class="remove-media"
     icon
     depressed
     @click="removeFile()"
    >
     <v-icon color="warning">
        fa fa-times
     </v-icon>
    </v-btn>
    </div>
    <v-file-input
      v-else
      v-model="value"
      class="file-input"
      color="primary"
      placeholder="Upload a document"
      required
      :accept="acceptedFIleTypes"
      prepend-inner-icon="fa-solid fa-file"
      persistent-hint
      :error-messages="fileErrors"
      @input="
        $v.value.$touch();
        inputFile();
      "
      @change="
        $v.value.$touch();
        inputFile();
      "
    >
     <template v-slot:selection="{ text }">
        <v-chip
         color="primary"
         dark
         label
         small
        >
         {{ text }}
        </v-chip>
     </template>
    </v-file-input>
  </div>
</template>

<script>
import { requiredIf } from "vuelidate/lib/validators";

export default {
    name: "DcoumentUpload",

    validations: {
        value: {
            required: requiredIf(function () {
                return this.requiredInput;
            }),
        },
    },

    props: {
        validation: {
            type: [Array, Object, File],
            required: false,
            default: function() {
                return {}
            },
        },
        currentFile: {
            type: [String, Array, File, Object],
            required: false,
            default: ''
        },
        requiredInput: {
            type: Boolean,
            required: false,
            default: true,
        }
    },

    data() {
        return {
            acceptedFIleTypes: "file/*",
            fileUrl: this.currentFile || null,
            value: null,
        };
    },

    computed: {
        fileErrors() {
            const errors = [];
            if(!this.$v.value.$dirty) return errors;
            !this.$v.value.required &&
              errors.push(this.$i18n.t(`ErrorMessages.required`));
              if(this.validation.file) {
                errors.push(this.validation.file[0]);
              }
              return errors;
        }
    },

    methods: {
        inputFile() {
            let file = this.value;

            this.$emit("update:file-url", file);
            this.$emit("update:in-valid", this.$v.$invalid);
        },
        removeFile() {
            this.fileUrl = '';
            this.value = null;
            this.$emit("update:in-valid", this.$v.$invalid);
            this.$emit("delete-file", '');
        },
    },
    watch: {
        currentFile(newVal) {
            if (newVal) {
                this.fileUrl = newVal[0]?.url;
                // this.fileUrl = newVal;
            }
        },
        value(newVal) {
            this.$v.$reset();
        },
    },
}
</script>

<style scoped>

</style>