<template>
    <v-text-field
        v-model.trim="inputValue"
        required
        outlined
        dense
        :prepend-inner-icon="icons"
        color="primary"
        :label="label"
        :error-messages="inputValueErrors"
        @input="
            $v.inputValue.$touch();
            emit();
        "
        @blur="
            $v.inputValue.$touch();
            emit();
        "
    >
    </v-text-field>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
export default {
    name: "MailInput",

    validations: {
        inputValue: {
            required,
            email,
        },
    },

    props: {
        currentValue: {
            type: String,
            required: false,
            default: "",
        },
        label: {
            required: true,
            type: String,
        },
        icons: {
            required: false,
            type: String,
            default: "",
        }
    },

    data() {
        return {
            inputValue: "",
        };
    },

    computed: {
        inputValueErrors() {
            const errors = [];
            if (!this.$v.inputValue.$dirty) return errors;
            !this.$v.inputValue.required &&
                errors.push(this.$i18n.t(`ErrorMessages.required`));
            !this.$v.inputValue.email &&
                errors.push(this.$i18n.t(`ErrorMessages.validEmail`));
            return errors;
        },
    },

    methods: {
        emit() {
            this.$emit("update:input-value", this.inputValue);
            this.$emit("update:in-valid", this.$v.$invalid);
        },
    },

    watch: {
        currentValue(newval) {
            if (newval) this.inputValue = newval
        },
    },

    created() {
        if (this.currentValue) {
            this.inputValue = this.currentValue;
        }
    },
};
</script>

<style lang="scss" scoped></style>
